import React from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import Fade from '../components/fade.jsx';

import useTranslate from '../hooks/use-translate.js';
import usePageContext from '../hooks/use-page-context.js';
import RichContent from '../components/rich-content.jsx';

export default function Home(props) {
	let { data } = props;
	let { language } = usePageContext();
	let { sanityBrainjarReference, allSanityBrainjarReference } = data;
	let { nodes: references } = allSanityBrainjarReference;
	let { id, logo, company, image, _rawLanguages: languages } = sanityBrainjarReference;
	let { title, description, content } = languages[language];
	let t = useTranslate('references');

	let renderReferencesOverview = [];
	for (let reference of references) {
		let className = classNames({ 'text-primary': reference.id === id }, { 'text-tertiary': reference.id !== id });
		let iconClassName = classNames(
			'h-8 flex-shrink-0',
			{ 'w-8 mr-1': reference.id === id },
			{ 'w-0 transition-all duration-200 transform group-hover:mr-1 group-hover:w-8': reference.id !== id },
		);

		renderReferencesOverview.push(
			<li key={reference.id} className={className}>
				<Link to={`../${reference.slug.current}`} className="flex items-center truncate group">
					<svg className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
					</svg>
					<span className="truncate">{reference.company}</span>
				</Link>
			</li>,
		);
	}

	let seoTitle = `Brainjar | ${title}`;

	return (
		<Layout seo={{ description, image }}>
			<Helmet>
				<title>{seoTitle}</title>
				<meta property="og:title" content={seoTitle} />
				<meta name="twitter:title" content={seoTitle} />
			</Helmet>

			<section className="grid min-h-screen">
				<h1 className="z-10 flex flex-col max-w-lg col-start-1 row-start-1 -mt-20 text-4xl font-semibold xl:max-w-2xl md:text-5xl 2xl:text-6xl self-y-center">
					<span className="text-primary">{company}</span>
					<span className="text-white">{title}</span>
				</h1>
				<div className="relative right-0 z-20 items-center justify-center hidden col-start-1 row-start-1 bg-no-repeat bg-cover lg:flex top-1/4 w-80 h-80 self-x-end">
					<Fade opacity="0.7" />
					<img src={logo.asset.url} className="z-10 w-2/5" alt={`Logo ${company}`} />
				</div>
				<div
					className="right-0 self-x-end filter brightness-75 absolute w-full h-full max-w-[95%] md:max-w-[80%] min-h-[50rem] col-start-1 row-start-1 bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${image?.asset?.url+"?h=800"})`,
						WebkitMaskImage: 'radial-gradient(ellipse at 60% 40%, white 30%, rgba(0, 0, 0, 0.0) 60%)',
					}}
				></div>
			</section>

			<Section>
				<div className="-mt-20 grid gap-20 xl:grid-cols-[minmax(0,1fr),minmax(15rem,20rem)]">
					<article className="w-full prose max-w-none xl:prose-xl">
						<RichContent blocks={content} />
					</article>
					<div className="sticky w-full max-w-sm top-32 self-y-start">
						<h2 className="text-3xl text-tertiary">{t('overview')}</h2>
						<ul className="mt-4 space-y-1">{renderReferencesOverview}</ul>
					</div>
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query Reference($id: String!) {
		sanityBrainjarReference(id: { eq: $id }) {
			id
			company
			_rawLanguages
			slug {
				current
			}
			logo {
				asset {
					url
				}
			}
			image {
				asset {
					url
				}
			}
		}

		allSanityBrainjarReference {
			nodes {
				id
				company
				slug {
					current
				}
			}
		}
	}
`;
